$theme: 'zephyr';
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$blue: #3459e6;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #da292e;
$orange: #f8765f;
$yellow: #f4bd61;
$green: #2fb380;
$teal: #20c997;
$cyan: #287bb5;
$primary: $blue;
$secondary: $white;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$min-contrast-ratio: 1.65;
$enable-shadows: true;
$body-color: $gray-200;
$headings-color: $gray-100;
$font-family-sans-serif: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI';
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875 !default;
$box-shadow: 0 1px 2px rgba($black, 0.05);
$box-shadow-lg: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;
$table-th-font-weight: 500;
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-font-size: $font-size-sm;
$btn-font-weight: 500;
$btn-box-shadow: $box-shadow;
$btn-focus-box-shadow: $box-shadow;
$btn-active-box-shadow: $box-shadow;
$form-label-font-weight: 500;
$input-box-shadow: $box-shadow;
$input-group-addon-bg: $gray-100;
$nav-link-color: $body-color;
$nav-link-hover-color: $body-color;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $primary;
$navbar-padding-y: 0.85rem;
$navbar-nav-link-padding-x: 0.75rem;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;
$navbar-light-color: $black;
$navbar-light-hover-color: $black;
$navbar-light-active-color: $black;
$dropdown-font-size: $font-size-sm;
$dropdown-border-color: $gray-300;
$dropdown-divider-bg: $gray-200;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 1rem;
$pagination-color: $light;
$pagination-focus-color: $pagination-color;
$pagination-hover-color: $pagination-color;
$pagination-hover-bg: $gray-100;
$card-spacer-x: 1.5rem;
$card-cap-padding-y: 1rem;
$card-cap-padding-x: 1.5rem;
$toast-header-color: $headings-color;
$modal-content-border-color: $gray-700;
$modal-header-border-width: 0;
$list-group-item-padding-y: 1rem;
$list-group-item-padding-x: 1.5rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-divider: quote('>');
$body-bg: $gray-800;
$card-color: $gray-200;
$card-bg: $dark;
$list-group-bg: $dark;
$pagination-bg: $dark;
$pagination-border-color: $gray-800;
$breadcrumb-bg: $dark;
$breadcrumb-divider-color: $light;
$breadcrumb-active-color: $light;
$input-bg: $dark;
$input-color: $light;
$card-border-width: 0;
$modal-content-bg: $dark;
$input-border-color: $gray-700;
$input-disabled-bg: $dark;
